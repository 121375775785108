@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #fbfbfb !important;
  font-family: "Public Sans", sans-serif !important;
}

.sign-banner-part {
  display: flex;
  align-items: center;
}

.left-banner-img {
  background-image: url("../Images/banner-sign.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.logo {
  margin-left: 46px;
  margin-top: 20px;
  position: absolute;
  top: 20px;
  z-index: 1;
}

.logo img {
  width: 140px;
}

.login-box-inner-wrap {
  max-width: 550px;
  margin: auto;
  padding: 45px;
  min-height: 450px;
}

.login-box-inner-wrap h2 {
  color: #444151;
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.6px;
}

.login-box-inner-wrap p {
  font-size: 15px;
  font-weight: 400;
  color: #6E6B78;
  font-family: "Public Sans", sans-serif;
}

.login-box-inner-wrap form {
  margin-top: 35px;
}

.login-box-inner-wrap label {
  color: #444151;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}

.login-box-inner-wrap input {
  background: #fff;
  border-radius: 6px;
  height: 42px;
}

.login-box-inner-wrap input:focus {
  box-shadow: none;
  border-color: #D1D0D4;
}

.form-select:focus {
  border-color: #dee2e6 !important;
  outline: 0;
  box-shadow: none !important;
}

.login-box-inner-wrap input::placeholder {
  color: #ACAAB1;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  /* 120% */
  letter-spacing: 0.3px;
}

.pass-rember-line {
  display: flex;
  justify-content: end;
  align-items: center;
}

.remember-check {
  display: flex;
  align-items: center;
  gap: 10px;
}

.remember-check input {
  height: 16px;
  width: 16px;
}

.login-box-inner-wrap input {
  background: #fff;
  border-radius: 7px;
  height: 42px;
}

/* .login-box-inner-wrap label {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
} */

.forgot {
  color: #7367f0;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  text-decoration: none;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  display: block;
}

.submit {
  margin-top: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  height: 42px;
  text-decoration: none;
  margin: auto;
  border-radius: 7px;
  background: #7367f0;
  box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.3);
}

.otp-banner-img {
  background-image: url("../Images/otp-banner.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.forgot-btn {
  margin-top: 30px !important;
}

.back-link-ad {
  text-align: center;
  margin-top: 20px;
}

.back-link-ad a {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  gap: 6px;
  color: #7367f0;
  text-decoration: none;
}


.reset-banner-img {
  background-image: url("../Images/reset-pass.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.error-banner-img {
  background-image: url("../Images/error-bg.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 30%;
  position: relative;
}

.error-text-box {
  max-width: 100%;
  margin: auto;
  text-align: center;
  padding-top: 90px;
}

.error-text-box h2 {
  font-size: 96px;
  font-style: normal;
  font-weight: 500;
  line-height: 96px;
}

.error-text-box>div {
  /* Basic Typography/h4 */
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  /* 158.333% */
}

.error-text-box p {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-top: 11px;
  color: #6C6976;
}

.error-text-box a {
  background: #7367f0;
  box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.3);
  padding: 10px 15px;
  color: #fff;
  border-radius: 8px;
  text-decoration: none;
  display: block;
  max-width: 140px;
  margin: auto;
}

.maintenance-banner-img {
  background-image: url("../Images/under-maintenance.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 30%;
  position: relative;
}

.resend-mail p {
  text-align: center;
  margin-top: 20px;
}

.resend-mail p a {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-decoration: none;
  color: #7367F0;
}

.login-box-inner-wrap p span {
  font-size: 15px;
  color: #444151;
  font-weight: 400;
}

.new-otp-banner-img {
  background-image: url("../Images/banner-otp.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


/* dashboard page css */

.main-wrap {
  display: flex;
  height: 100vh;
}

aside {
  background: #fff;
  width: 270px;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 1;
  box-shadow: 0px 2px 8px 0px rgba(47, 43, 61, 0.12);
}

.toggle-icon {
  position: fixed;
  left: 245px;
  border: 2px solid #fff;
  padding: 5px 6px;
  background: #071523;
  filter: drop-shadow(0px 6px 10px rgba(151, 151, 151, 0.47));
  width: 40px;
  height: 40px;
  top: 70px;
  display: flex;
  justify-content: center;
  z-index: 1;
  border-radius: 62px;
  align-items: center;
  cursor: pointer;
}

.toggle-icon img {
  width: 20px;
  filter: invert(1);
}

.logo-side img {
  width: 135px;
  margin: 25px 23px;
}

.logo-side {
  display: flex;
  align-items: center;
  justify-content: center;
}

.side-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 170px);
}

.side-menu .side-btm-space {
  margin-bottom: 5px;
}

.side-menu a.bar_active {
  color: #ffffff;
  border-radius: 6px;
  background: #93E9BE;
  box-shadow: 0px 2px 6px 0px rgba(147, 233, 190, 0.30);
  margin: 0px 10px;
  padding: 12px 15px;
}

.side-menu a {
  color: #000;
  display: flex !important;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  padding: 10px 25px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  text-decoration: none;
  display: block;
}

.side-menu a.bar_active {
  color: #2F2B3DE5;
}

.side-menu a {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  align-items: center;
}

a.menu-btn.bar_active path {
  stroke: #2F2B3DE5;
}

.side-menu a svg {
  margin-right: 13px;
  width: 22px;
}

.side-menu a p {
  margin: 0;
}

.right-side-cmn {
  width: calc(100% - 270px);
  position: relative;
  margin-left: auto;
  background: #fbfbfb;
  height: 100vh;
}

.dashboard-items {
  margin: 30px 0 10px 10px;
}

.right-top-bar {
  border-radius: var(--Border-Radius-border-radius-md, 6px);
  box-shadow: 0px 2px 8px 0px rgba(47, 43, 61, 0.12);
  padding: 4px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
}

.left-side-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.left-side-bar input {
  border: 0 !important;
  background-color: transparent;
  outline: none !important;
}

.left-side-bar input::placeholder {
  color: #ACAAB1;
  font-size: 15px;
  font-weight: 400;

}

.right-side-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.right-top-bar .user-img-top img {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.dashboard-items {
  margin: 30px 0 10px 10px;
}

.select-box {
  display: flex;
  justify-content: center;
  width: 100%;
}


.dashbox-inner-wrap select.form-select {
  border: 1px solid #b9b5ab;
  background: #fff;
  color: #000;
  font-family: "Public Sans", sans-serif;
  height: 40px;
  width: 200px;
  padding-left: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  line-height: 24px;
  /* 160% */
  letter-spacing: -0.3px;
  border-radius: 0;
  background-image: url("../Images/Expand_down.svg");
  background-repeat: no-repeat;
  background-position: center right 10px;
}

.dash-inner-boxes {
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 15%);
  padding: 25px 22px;
  margin-top: 10px;
  border-radius: 6px;
}

.session {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
}

.left-session p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #444151;
  margin-bottom: 5px;
}

.left-session h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  color: #444151;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.left-session h2 span {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #28C76F;
}

span.total-users {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #6E6B78;
}

.filters {
  margin-left: 10px;
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 15%);
  border-radius: 6px;
  margin-top: 20px;
}

.user-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 22px;
}

.search-tab {
  display: flex;
  align-items: center;
  gap: 10px;
}

.export-box p {
  margin: 0px;
  display: flex;
  gap: 9px;
  align-items: center;
}

.export-box p {
  margin: 0px;
  display: flex;
  gap: 9px;
  align-items: center;
  color: #808390;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
}

.export-box {
  background: #EAEBED;
  padding: 8px 17px;
  border-radius: 6px;
  cursor: pointer;
}

.input-search input {
  background: #fff;
  border-radius: 6px;
  width: 271px;
  border: 1px solid #D1D0D4;
  font-size: 15px;
  height: 39px;
  font-weight: 400;
}

.input-search input::placeholder {
  color: #D1D0D4;
}

input#formBasicPassword {
  box-shadow: none;
  border: 1px solid #D1D0D4;
}

.select-box {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 70px;
}

.table-responsive {
  border-top: 1px solid #E6E5E7;
}

.table-responsive thead tr th {
  padding: 14px 10px;
  font-size: 13px;
  color: #444151;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
}

.user-checkbox {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-profile img {
  height: 34px;
  width: 34px;
}

.first-user {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-profile {
  display: flex;
  gap: 10px;
  align-items: center;
}

.user-id p {
  font-size: 15px;
  color: #444151;
  font-weight: 500;
  margin: 0px;
}

.user-id span {
  font-size: 13px;
  color: #6E6B78;
  font-weight: 400;
  margin: 0px;
}

.table-responsive tbody tr td {
  padding: 5px 10px;
  vertical-align: middle;
}

.user-role p {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  font-weight: 400;
  margin: 0px;
  color: #444151;
}

.debit {
  font-size: 13px;
  font-weight: 500;
  line-height: 47px;
  color: #6E6B78 !important;
}

.pending p {
  color: #FF9F43;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
}

.pending {
  background: #FFEFE1;
  text-align: center;
  max-width: 90px;
  padding: 5px;
  border-radius: 6px;
}

.table-responsive td .active {
  background: #DCF6E8;
  text-align: center;
  max-width: 90px;
  padding: 5px;
  border-radius: 6px;
}

.table-responsive td .active p {
  color: #28C76F;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;

}

.action {
  display: flex;
  gap: 16px;
  align-items: center;
}

.table-responsive thead tr th:before {
  position: absolute;
  content: "";
  background: #E6E5E7;
  width: 2px;
  height: 26px;
  right: 20px;
  top: 11px;
}

.user-role {
  line-height: 47px;
}

.showing-user p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #ACAAB1;
  margin: 0px;
}

.pagination-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-responsive table {
  margin: 0px;
}

.pagination-section {
  padding: 10px;
}

.pagination-block a.page-link {
  background: #EFEEF0;
  border-radius: 6px;
  color: #423F4F;
  font-size: 13px;
  font-weight: 400;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  line-height: 38px;
  justify-content: center;
  padding: 0px;
}

.pagination-block ul {
  margin: 0px !important;
  gap: 6px;
}

.pagination-block li.page-item a span {
  font-size: 25px;
}

.pagination-block li.page-item.active .page-link {
  border: 1px solid #28C76F;
  background: #28C76F;
  border-radius: 6px;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  line-height: 38px;
  justify-content: center;
}

.pagination-block .page-link:focus {
  box-shadow: none !important;
}

.pagination-block li.page-item:hover a {
  background: #E8E6FC;
  color: #7367F0;
}

.made-with {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  padding: 10px 0;
  margin-top: 23px;
}

.pixinvent p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin: 0px;
  color: #6C6976;
}

.pixinvent p span {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin: 0px;
  color: #7367F0;
}

.bottom-links a {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin: 0px;
  color: #7367F0;
  text-decoration: none;
}

.bottom-links {
  display: flex;
  align-items: center;
  gap: 16px;
}

.set-otp input {
  width: calc(100% / 6) !important;
  border: 1px solid #D1D0D4;
  outline: none;
  height: 50px !important;
}

.set-otp>div {
  justify-content: space-between !important;
  gap: 10px;
}

.notify-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notify-head p {
  margin: 0;
}

.notify-head>div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.notify-head>div span {
  background-color: #e8e6fc;
  padding: 10px;
  border-radius: 5px;
  color: #7367f0;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
}

.drop-pro-view {
  display: flex;
  gap: 5px;
  align-items: center;
}

.logout-btn {
  background-color: #ff4c51 !important;
  color: #fff !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 3px !important;
  margin: 0px 10px !important;
  border-radius: 8px !important;
  width: 179px !important;
  margin-top: 10px !important;
}

.admin-name h3 {
  color: #444151;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  margin: 0px;
}

.admin-name p {
  font-size: 13px;
  font-weight: 400;
  color: #ACAAB1;
  line-height: 20px;
  margin: 0px;
}

.my-profile a {
  color: #444151;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  gap: 9px;
  align-items: center;
  margin-bottom: 5px;
}

.user-img-top button#dropdown-basic {
  background: none;
  border: none;
  padding: 0px;
  width: 50px;
  border-radius: 50%;
  height: 50px;
}

.notification-icon button#dropdown-basic {
  background: none;
  border: none;
  padding: 0px;
  width: 25px;
  border-radius: 50%;
  height: 25px;
}

.notification-icon .dropdown-item:active {
  background: #f8f9fa !important;
  color: #444151 !important;
}

.user-img-top .dropdown-item:active {
  background: #f8f9fa !important;
  color: #444151 !important;
}

.notify-head p {
  color: #444151;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
}

.user-notfic-icon img {
  width: 40px;
  height: 40px;
}

/* end dashboard */

/* my profile page */
.profile-top-box {
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(47, 43, 61, 0.12);
  padding: 18px 18px;
  margin-left: 10px;
  background: #fff;
}

.photo-upload {
  display: flex;
  gap: 25px;
  align-items: center;
}

.btn-up button {
  margin-top: 13px;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  background: #93E9BE;
  padding: 10px 25px;
  border-radius: 6px;
  border: none;
  color: #2F2B3DE5;
  box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.3);
}

.btn-up {
  display: flex;
  gap: 20px;
  align-items: center;
}

.reset-box p {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  background: #EAEBED;
  padding: 10px 15px;
  border-radius: 6px;
  color: #808390;
  margin: 0px;
}

p.image-disp {
  color: #6E6B78;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 16px;
  margin-bottom: 0px;
}

.input-profile {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
}

.input-field-width {
  width: 50%;
}

.input-field-width input::placeholder {
  color: #ACAAB1;
}

.form-control:focus {
  box-shadow: none !important;
  border: 1px solid #D1D0D4 !important;
}

.profile-disp label {
  margin-bottom: 3px;
}

.heading-profile h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: #444151;
  margin: 0px;
  text-transform: uppercase;
}

.password-req h4 {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  color: #6E6B78;
  margin: 0px;
}

.password-req p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #6E6B78;
  margin: 0px;
  margin-top: 12px;
  display: flex;
  gap: 8px;
}

.user-verification img {
  background: #7367F0;
  padding: 7px;
  height: 33px;
  width: 33px;
  border-radius: 6px;
}

.user-verification img:nth-child(2) {
  background: #FF4C51;
  padding: 7px;
  height: 33px;
  width: 33px;
  border-radius: 6px;
}

.cont-num {
  line-height: 47px;
  color: #444151;
}

a.menu-btn.bar_active ellipse {
  stroke: #2F2B3DE5;
}

/* end */

input[type=checkbox] {
  position: relative;
  border: 2px solid #ACAAB1;
  border-radius: 2px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 .6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  border-radius: 6px;
}


input[type=checkbox]:checked {
  background-color: #7367F0;
  opacity: 1;
  border: 1px solid #7367F0;
}

input[type=checkbox]:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 6px;
  height: 11px;
  border: solid #FFF;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.side-pages {
  padding: 10px 25px;
}

.side-pages p {
  margin: 0px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #ACAAB1;
}

.pass-eys img {
  position: absolute;
  top: 0px;
}

.pass-eys {
  position: relative;
}

.pass-eys img {
  position: absolute;
  top: 39px;
  right: 19px;
  cursor: pointer;
}

.file-input {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  background: #93E9BE;
  padding: 10px 25px;
  border-radius: 6px;
  border: none;
  color: #2F2B3DE5;
  box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.3);
}

.image-heading-text {
  padding: 23px 0px 23px 23px;
  background: #fff;
  border-bottom: 1px solid #E6E5E7;
  border-radius: 6px 6px 0px 0px;
}

.image-heading-text h2 {
  color: #2F2B3DE5;
  font-feature-settings: 'liga'off, 'clig'off;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 0px;
}

.pending {
  text-align: center;
  max-width: 168px;
  padding: 5px;
  border-radius: var(--Border-Radius-border-radius-sm, 4px);
  background: #28C76F29;
}

.pending p {
  align-items: center;
  justify-content: center;
  gap: 6px;
  display: flex;
}

.pending p {
  color: #28C76F;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
}

.open {
  text-align: center;
  padding: 5px 17px;
  border-radius: 4px;
  background: #28C76F29;
  max-width: fit-content;
}

.open p {
  color: #28C76F;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
}

.create-notification-page {
  border-radius: 6px;
  background: #fff;
  padding: 31px 35px 35px 25px;
}

.category-field {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.category-field .input-select {
  width: 100%;
}

.category-field .input-select label {
  color: #2F2B3DE5;
  font-feature-settings: 'liga'off, 'clig'off;
  font-family: "Public Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin: 0px;
}

.category-field .modal-input {
  width: 100%;
}

.textarea-input {
  margin-top: 15px;
}

.create-btn {
  padding-top: 40px;
  text-align: center;
}

.reason-max-width {
  max-width: 759px;
}

.video-section {
  margin-top: 30px;
}

.video-section p {
  color: #2F2B3DE5;
  font-feature-settings: 'liga'off, 'clig'off;
  font-family: "Public Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin-bottom: 9px;
}

.add-videos {
  border-radius: 6px;
  border: 1px solid #dee2e6;
  padding: 10px;
}

.add-videos video {
  border-radius: 6px;
}

.profile-info p {
  color: #071523;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.bank-det-heading h2 {
  color: #071523;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-transform: uppercase;
}

.heading-profile p {
  color: rgba(7, 21, 35, 0.70);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 19px;
}

.activity-list-user ul li p {
  color: rgba(7, 21, 35, 0.70);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
}

.activity-list-user ul li span {
  color: rgba(7, 21, 35, 0.70);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.activity-list-user ul {
  list-style: none;
  padding: 0px;
}

.activity-list-user ul li {
  margin-top: 12px;
  border-bottom: 1px solid #B9B5AB;
  padding-bottom: 5px;
}

.activity-list-user ul li span {
  display: block;
  text-align: right;
  margin-top: 9px;
}

.inner-page-lable a {
  align-items: center;
  color: #071523;
  display: flex;
  font-size: 30px;
  font-weight: 700;
  gap: 15px;
  letter-spacing: -.6px;
  line-height: 32px;
  margin: 0;
  padding: 14px 0;
  text-decoration: none;
}

.create-btn button {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  padding: 10px 25px;
  border-radius: 6px;
  border: none;
  color: #2F2B3DE5;
  background: #93E9BE;
  box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.30);
}

.create-new-btn button {
  border-radius: 6px;
  color: #2F2B3DE5;
  background: #93E9BE;
  border: none;
  padding: 7px 17px;

  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
}

.add-categories-page {
  max-width: 700px;
  margin: auto;
  margin-top: 43px;
}

.create-date-range {
  border-radius: 6px;
  border: 1px solid #93E9BE;
  padding: 8px 16px;
}

.create-date-range p {
  color: #28C76F;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  text-transform: capitalize;
  margin: 0px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.download-btn-st {
  border-radius: 6px;
  background: #93E9BE;
}

.download-btn-st p {
  color: #FFF;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
}

.close {
  text-align: center;
  background: rgba(255, 76, 81, 0.16);
  padding: 5px 12px;
  max-width: fit-content;
}

.close p {
  color: #FF4C51;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
}

.image-heading-text {
  padding: 23px 0px 23px 23px;
  background: #fff;
  border-bottom: 1px solid #E6E5E7;
  border-radius: 6px 6px 0px 0px;
}

.image-heading-text h2 {
  color: #2F2B3DE5;
  font-feature-settings: 'liga'off, 'clig'off;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 0px;
}

.support-chat-box-main {
  word-wrap: break-word;
  background-clip: initial;
  background-color: #f3f2f5;
  border-radius: .25rem;
  box-shadow: 0 3px 12px 0 #2f2b3d24;
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding: 22px;
  position: relative;
}

.support-msg-box {
  -ms-overflow-style: none !important;
  overflow-y: scroll;
  scrollbar-width: none !important;
  height: 65vh;
  padding: 20px 10px;
}

.user-box {
  margin-bottom: 15px;
}

.user-top-hdng {
  align-items: center;
  display: flex;
}

.user-top-hdng img {
  border-radius: 50px;
  height: 30px;
  margin-right: 10px;
  width: 30px;
}

.user-top-hdng h3 {
  font-size: 22px;
  margin-right: 11px;
  text-transform: capitalize;
}

.user-top-hdng h4 {
  font-size: 11px;
  margin: 0;
}

.user-msg-box {
  background: #fbfbfb;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  color: #000;
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  margin-left: 35px;
  margin-top: 10px;
  padding: 15px 25px;
}

.user-msg-box p {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

.send-box {
  bottom: 0;
  position: absolute;
  width: 97%;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.send-box .input-group-text {
  align-items: center;
  background-color: #7367f0;
  border: 0;
  border-radius: 10px !important;
  bottom: 0;
  box-shadow: 0 2px 6px 0 #7367f04d;
  color: #fff;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  gap: 10px;
  line-height: 1.5;
  margin: 5px;
  padding: .375rem .95rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  white-space: nowrap;
  z-index: 99;
}

.send-feild {
  background: #fbfbfb !important;
  border: none !important;
  border-radius: 5px !important;
  padding: 15px !important;
}

.share-btn img.spicker {
  position: absolute;
  right: 109px;
  top: 11px;
}

.share-op {
  position: absolute;
  right: 150px;
  top: 11px;
}

.send-box input {
  padding-right: 200px;
}

.pointer {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}